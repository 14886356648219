import React, { useEffect } from "react"
import { Link } from "gatsby"
import $ from "jquery"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import { Fade } from "react-awesome-reveal"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = () => {
  useEffect(() => {
    $(".home-slider--action").click(function () {
      $(".home-slider").toggleClass("video-mode")
    })
  })

  const settingsSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: false,
        },
      },
    ],
  }

  return (
    <Layout>
      <SEO title="Home" />
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <section className="home-slider">
        <video width="100%" height="100%" loop autoPlay playsInline muted>
          <source
            src={require("../assets/video/intro-movie.mp4")}
            type="video/mp4"
          />
        </video>
        <button className="home-slider--action"></button>
        <Slider {...settingsSlider}>
          <div>
            <div className="container">
              <div className="home-slider--inner">
                <h4>Mała skala</h4>
                <h2>Wielkie emocje</h2>
                <p>
                  <strong>
                    Zastanawiasz się jak uatrakcyjnić swoje wydarzenie?
                  </strong>{" "}
                  Dzięki modelom samochodów zdalnie sterowanych możesz być
                  pewien, że Twoi klienci, pracownicy lub goście będą wspominać
                  Twoją imprezę przez lata.
                </p>
                <a href="/#oferta" className="btn-custom">Poznaj ofertę</a>
              </div>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="home-slider--inner">
                <h4>Pełne bezpieczeństwo</h4>
                <h2>Maksimum adrenaliny</h2>
                <p>
                  Przez zainstalowanie w naszych modelach technlogii FPV możesz
                  poczuć się jakbyś siedział w kabinie samochodu. Nowa forma
                  sterowania samochodami da Ci jeszcze większą zabawę przy
                  pokonywaniu przeszkód!
                </p>
                <a href="/#oferta" className="btn-custom">Poznaj ofertę</a>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <span className="anchor" id="oferta"></span>
      <section className="home-variety">
        <div className="container">
          <h3>
            Wiemy jak <span>urozmaicić</span>
            <br /> Twoje wydarzenie!
          </h3>
          <div className="row">
            <div className="col-md-4">
              <Fade delay={150} direction={"top"} triggerOnce>
                <div
                  className="home-variety--thumb"
                  style={{
                    backgroundImage: `url(${require(`../assets/images/variety-image-1.jpg`)})`,
                  }}
                ></div>
                <div className="home-variety--inner">
                  <span>krok 1</span>
                  <h4>Niepowtarzalność</h4>
                  <p>
                    Zwróć uwagę na swoją firmę, zapewnij gościom hotelowym
                    wspaniałą rozrywkę lub rozwijaj swój zespół w niecodzienny
                    sposób.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={300} direction={"top"} triggerOnce>
                <div
                  className="home-variety--thumb"
                  style={{
                    backgroundImage: `url(${require(`../assets/images/variety-image-2.jpg`)})`,
                  }}
                ></div>
                <div className="home-variety--inner">
                  <span>krok 2</span>
                  <h4>Profesjonalna organizacja</h4>
                  <p>
                    Pasja, wiedza i świetny kontakt z uczestnikami to elementy
                    towarzyszące współpracy na torze z Akademią RC.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={450} direction={"top"} triggerOnce>
                <div
                  className="home-variety--thumb"
                  style={{
                    backgroundImage: `url(${require(`../assets/images/variety-image-3.jpg`)})`,
                  }}
                ></div>
                <div className="home-variety--inner">
                  <span>krok 3</span>
                  <h4>Nowoczesne podejście</h4>
                  <p>
                    Modele zdalnie sterowane są powszechnie znane, jednak ich
                    nowatorskie wykorzystanie sprawi, że każdy będzie miło
                    wspominał Twoje wydarzenie lub szkolenie
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section className="home-offerpdf">
        <div className="container">
          <h2>Zobacz naszą ofertę!</h2>
          <Link href={'/ARC_Oferta_2023.pdf'} className="btn-custom btn-custom--reverse">
            Pobierz PDF
          </Link>
        </div>
      </section>

      <section className="home-offer">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md-4 col-sm-6">
              <div className="home-offer--tile">
                <h4>
                  Akademia RC sprawi, że każdy <br /> uczestnik{" "}
                  <span>zapamięta twoją firmę</span> <br /> i wróci po więcej!
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="home-offer--tile">
                <h4>
                  Wyczynowe modele <br /> zdalnie sterowane
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="home-offer--tile">
                <h4>
                  Pełen wyzwań <br /> tor
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="home-offer--tile">
                <h4>
                  Nowy wymiar <br />
                  adrenaliny (FPV)
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="home-offer--tile">
                <h4>
                  Współpraca <br />
                  lub rywalizacja
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="home-offer--tile">
                <div>
                  <h4>
                    Dopasowanie do <br />
                    indywidualnych <br /> preferencji.
                  </h4>
                  <Link
                    to="#kontakt"
                    className="btn-custom btn-custom--reverse"
                  >
                    Napisz do Nas
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <span className="anchor" id="branding"></span>
      <section className="home-brand">
        <div className="container">
          <h2>Zaprezentuj swoją markę!</h2>
          <Link href={'/ARC_Branding_2023.pdf'} className="btn-custom btn-custom--reverse">
            Pobierz PDF
          </Link>
        </div>
      </section>

      <span className="anchor" id="dla-kogo"></span>
      <section className="home-event">
        <div className="container">
          <h2>
            Pomożemy Ci stworzyć <br /> <span>niepowtarzalne</span> wydarzenie
          </h2>
          <div className="row">
            <div className="col-md-4">
              <Fade delay={150} direction={"top"} triggerOnce>
                <div className="home-event--tile">
                  <div
                    className="home-event--thumb"
                    style={{
                      backgroundImage: `url(${require(`../assets/images/event-image-1.jpg`)})`,
                    }}
                  ></div>
                  <h4>Eventy, Pokazy, Targi</h4>
                  <ul>
                    <li>wyróżnij się na tle innych</li>
                    <li>coffee break pełen emocji</li>
                    <li>zachęć ludzi do rozmowy</li>
                    <li>nawiązuj nowe kontakty</li>
                    <li>przyciągnij uwagę</li>
                  </ul>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={300} direction={"top"} triggerOnce>
                <div className="home-event--tile">
                  <div
                    className="home-event--thumb"
                    style={{
                      backgroundImage: `url(${require(`../assets/images/event-image-2.jpg`)})`,
                    }}
                  ></div>
                  <h4>Hotele</h4>
                  <ul>
                    <li>Poznaj swoich gości</li>
                    <li>zachęć gości do wyboru twojej oferty</li>
                    <li>Wzbogać bazę atrakcji</li>
                    <li>zapewnij gościom rozrywkę</li>
                    <li>koniec nudnych wieczorów</li>
                  </ul>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={450} direction={"top"} triggerOnce>
                <div className="home-event--tile">
                  <div
                    className="home-event--thumb"
                    style={{
                      backgroundImage: `url(${require(`../assets/images/event-image-3.jpg`)})`,
                    }}
                  ></div>
                  <h4>Szkolenia</h4>
                  <ul>
                    <li>poznaj swoich pracowników</li>
                    <li>zobacz jak radzą sobie pod presją</li>
                    <li>sprawdź ich zdolności organizacyjne</li>
                    <li>wyłoń lidera</li>
                    <li>postaw na współpracę</li>
                  </ul>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <span className="anchor" id="modele"></span>
      <section className="home-baner">
        <div className="container">
          <h2>Modele terenowe</h2>
          <p>
            Nasze modele to w pełni <br /> profesjonalni pogromcy terenu
          </p>
          <Link
            to="/modele-terenowe/"
            className="btn-custom btn-custom--reverse"
          >
            Zobacz szczegóły
          </Link>
        </div>
      </section>

      <section className="home-social">
        <h4>Śledź nas na:</h4>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/Akademia-RC-105622287631307/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/ico-fb.svg")} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/akademia.rc/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/ico-inst.svg")} alt="" />
            </a>
          </li>
        </ul>
      </section>

      <span className="anchor" id="kontakt"></span>
      <section className="home-contact">
        <div className="container">
          <h2>Skontaktuj się z Nami</h2>
          <div className="row justify-content-center">
            <div className="col-md-3 text-center">
              <div className="ico ico--location"></div>
              <address>
                Akademia RC <br />
                ul. Wadowicka 8A <br />
                30-415 Kraków, PL
              </address>
              <div className="ico ico--phone"></div>
              <a href="tel:+48607534977">+48 607 534 977</a>
              <a href="tel:+48792125629">+48 792 125 629</a>
              <div className="ico ico--email"></div>
              <a href="mailto:kontakt@akademia-rc.pl">kontakt@akademia-rc.pl</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
